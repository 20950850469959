import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import ContentContainer from '../components/common/ContentContainer';
import Layout from '../components/Layout';
import SectionHeader from '../components/SectionHeader';
import SEO from '../components/Seo';
import Author from './../components/blog/Author';
import LinkCard from './../components/common/LinkCard';
import { formatDate } from './../util';

type BlogPost = {
  id: string;
  title: string;
  createdAt: string;
  body: any;
  slug: string;
  author: {
    firstName: string;
    lastName: string;
    image: {
      fluid: {
        src: string;
      };
    };
  };
  publishedDate: Date;
  blogCategory: { name: string }[];
  featuredImage: {
    fluid: {
      src: string;
    };
  };
};

interface BlogCardProps {
  blogPost: BlogPost;
  blogFallbackImage: any;
}

interface BlogCategoryPillProps {
  name: string;
}

const BlogCategoryPill = (props: BlogCategoryPillProps) => {
  return (
    <span className="rounded-full px-3 py-1 text-sm   bg-blue-200 text-primary">
      {props.name}
    </span>
  );
};

const BlogCard: React.FC<BlogCardProps> = props => {
  return (
    <div className="mb-4" key={props.blogPost.id}>
      <LinkCard
        linkPath={`/blog/${props.blogPost.slug}`}
        image={
          props.blogPost.featuredImage?.fluid?.src ??
          props.blogFallbackImage.childImageSharp.sizes.src
        }
        title={props.blogPost.title}
      >
        <div className="">
          <p className="text-sm text-gray-500 uppercase mt-1">
            {formatDate(new Date(props.blogPost.publishedDate), true)}
          </p>
          <div className="mt-3">
            <Author
              author={{
                imageSrc: props.blogPost.author.image?.fluid?.src,
                firstName: props.blogPost.author.firstName,
                lastName: props.blogPost.author.lastName
              }}
            />
          </div>
        </div>
      </LinkCard>
    </div>
  );
};

const Blog: React.FC = () => {
  const data = useStaticQuery(graphql`
    {
      blogFallbackImage: file(
        relativePath: { eq: "images/news-fallback.png" }
      ) {
        childImageSharp {
          sizes(maxWidth: 600) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      allContentfulBlogPost(
        filter: { node_locale: { eq: "en-US" } }
        sort: { fields: publishedDate, order: DESC }
      ) {
        edges {
          node {
            id
            body {
              body
            }
            featuredImage {
              fluid {
                src
              }
            }
            title
            publishedDate
            createdAt
            slug
            author {
              firstName
              lastName
              image {
                fluid {
                  src
                }
              }
            }
          }
        }
      }
    }
  `);

  const blogPosts = data.allContentfulBlogPost.edges.map((b: any) => b.node);

  return (
    <Layout>
      <SEO title="Blog" />
      <ContentContainer>
        <SectionHeader header="Blog" />
        <div className="flex mt-8">
          <div className="w-full flex flex-wrap">
            {blogPosts.map((blogPost: BlogPost) => (
              <div className="w-full" key={blogPost.id}>
                <BlogCard
                  blogPost={blogPost}
                  blogFallbackImage={data.blogFallbackImage}
                />
              </div>
            ))}
          </div>
        </div>
      </ContentContainer>
    </Layout>
  );
};

export default Blog;
